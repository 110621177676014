export const padZerosBehind = (amount: any, dp: number) => {
  return String(amount * 10 ** dp);
};

export const formatDollar = (amount: any) => {
  amount = parseFloat(amount);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(amount);
};
