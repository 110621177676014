import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "src/components";
import { AT_URL } from "src/config";

export const Submit = (props: any) => {
  const { t } = useTranslation();
  return (
    <Container title={t("title")}>
      <div className="h-screen flex jusify-center items-center px-6 text-center pb-20">
        <div className="flex flex-col">
          <p>{t("approvalNotice")}</p>
          <div className="pt-5">
            <button
              className="primary py-3 px-20"
              onClick={() => window.open(AT_URL)}
            >
              {t("toAT")}
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};
