import React from "react";
import { useTranslation } from "react-i18next";

const INPUT_CLASS =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

export const AddressBlk = (props: any) => {
  const { t } = useTranslation();
  const {
    room,
    setRoom,
    floor,
    setFloor,
    block,
    setBlock,
    apartmentName,
    setApartmentName,
    road,
    setRoad,
    district,
    setDistrict,
  } = props;

  const FIELDS = [
    {
      name: "room",
      value: room,
      placeHolder: "",
      type: "text",
    },
    {
      name: "floor",
      value: floor,
      placeHolder: "",
      type: "text",
    },
    {
      name: "block",
      value: block,
      placeHolder: "",
      type: "text",
    },
    {
      name: "apartment_name",
      value: apartmentName,
      placeHolder: "",
      type: "text",
    },
    {
      name: "road",
      value: road,
      placeHolder: "",
      type: "text",
    },
    {
      name: "district",
      value: district,
      placeHolder: "",
      type: "text",
    },
  ];

  const handleChange = (e: any) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "room") return setRoom(value);
    if (name === "floor") return setFloor(value);
    if (name === "block") return setBlock(value);
    if (name === "apartment_name") return setApartmentName(value);
    if (name === "road") return setRoad(value);
    if (name === "district") return setDistrict(value);
  };

  return (
    <div>
      {/* Blk Header */}
      <div className="py-4 bg-primary">
        <h5 className="px-6">{t("address.title")}</h5>
      </div>

      {/* Blk */}
      <div className="bg-white drop-shadow-md pb-5 px-6 pt-5 relative">
        <div className="flex flex-row justify-between flex-wrap">
          {FIELDS.map((item: any, index: number) => {
            return (
              <div className="w-[47%] pb-5" key={index}>
                <p>{t(`address.${item.name}`)}</p>
                {item.type === "text" && (
                  <input
                    value={item.value}
                    name={item.name}
                    onChange={(e) => handleChange(e)}
                    className={INPUT_CLASS}
                    placeholder={
                      item.placeHolder && t(`address.${item.placeHolder}`)
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
