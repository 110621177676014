export const tcTranslation = {
  changeLang: "Language",
  title: "開通 AT 先購買後付款服務",
  ploan: {
    title: "需要多少跟多久？",
    requiredAmount: "需要金額",
    desiredMonth: "還款期數",
    month: "個月",
    terms: "每月還款額* 低至",
    calculating: "計算中⋯",
    notes:
      "* 上述每月還款額是根據每月平息0.15%和不高於實際年利率3.51%計算，視乎還款期而定。個人貸款利息計算器上顯示的還款金額和還款期限僅供參考。",
  },
  knowMore: {
    title: "讓我們更了解您",
    name_title: "稱謂*",
    eng_surname: "英文姓氏*",
    hkid_same: "（須與香港身份證相同）",
    mr: "先生",
    miss: "小姐",
    lady: "女士",
    mrs: "太太",
    eng_name: "英文名字*",
    hkid: "香港身份證號碼*",
    hkid_sample: "Z23456(7)",
    gender: "性別*",
    male: "男",
    female: "女",
    dob: "出生日期*",
    dob_sample: "DD / MM / YY",
    email: "電郵地址*",
    email_sample: "name@domain.com",
    mobile: "手提電話號碼*",
    mobile_sample: "6xxxxxxx",
    isHk: "請問是否香港永久性居民？*",
    yes: "是",
    no: "否",
    country: "國藉*",
    education: "教育程度*",
    under_secondary: "中學或以下",
    training: "工業/職業訓練學院",
    associate: "副學士課程",
    bachelor: "學士學位",
    master_above: "碩士或以上",
    livingStatus: "住宅狀況*",
    self_house: "自置物業",
    mortgage: "按揭",
    dormitory: "宿舍",
    renting: "租屋",
    living_with_cousin: "與親屬同住",
    phoneNum: "家居電話號碼",
    marriageStatus: "婚姻狀況*",
    single: "未婚",
    married: "已婚",
    divorced: "離婚",
    widowed: "喪婚",
  },
  address: {
    title: "通訊地址",
    room: "室 (如適用)",
    floor: "樓*",
    block: "座 (如適用)",
    apartment_name: "大廈名稱／屋苑名稱*",
    road: "街道名稱／號碼*",
    district: "地區*",
  },
  bank: {
    title: "直接付款授權銀行資料",
    bank_name: "銀行名稱*",
    bank_account: "銀行號碼*",
  },
  others: {
    debt: "現有負債 (如適用)",
    purpose: "借貸目的",
    title: "其他",
    read: "我已閱讀並同意",
    terms: "條款及細則",
    and: "和",
    conditions: "標準個人貸款協議書",
    disclaimer:
      "我在此聲明，盡我所知，我以上所提供的所有信息將是準確的。我也明白，我對聲明負責，如果提供的任何信息不准確或有任何誤導性，我將承擔所有法律責任。",
    notes:
      "在訂立任何貸款協議之前，請擬借款人述明有否因促致、洽商、取得或申請該筆貸款，或因擔保或保證該筆貸款的償還，或由於與該等事務有關，而與任何人（下稱「第三方」）達成或簽訂了任何協議（下稱「第三方協議」）（擬借款人與其委任的律師純粹為提供法律服務而達成或簽訂的協議除外）?",
    yes: "是",
    no: "否",
  },
  submit: "立即提交",
  messages: {
    success: "成功",
    warning: "還差一步",
    error: "抱歉",
    requiredAgreeTerm: "請確認條款及細則。",
    requiredDisclaimer: "請確認聲明。",
    requiredPrivacy: "請確認協議內容。",
    requiredFields: "請填寫所有必填項目。",
    emailInvalid: "請確認電郵地址。",
    formSubmitted: "表格已經提交。",
    requiredUserId: "無法獲得用戶資料。",
    formError: "暫時未能成功提交表格。",
  },
  approvalNotice:
    "表格已順利提交，我們會於一至三個工作天內審批。審批成功後即可使用 AsiaTop 先購買後付款服務。",
  toAT: "前往 AsiaTop",
};
