import React from "react";
import { useTranslation } from "react-i18next";
const { Select } = require("antd");

const INPUT_CLASS =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

export const KnowMoreBlk = (props: any) => {
  const { t } = useTranslation();
  const {
    title,
    setTitle,
    engName,
    setEngName,
    engSurname,
    setEngSurname,
    hkid,
    setHkid,
    gender,
    setGender,
    dob,
    setDob,
    email,
    setEmail,
    mobile,
    setMobile,
    isHk,
    setIsHk,
    country,
    setCountry,
    education,
    setEducation,
    livingStatus,
    setLivingStatus,
    phoneNum,
    setPhoneNum,
    marriageStatus,
    setMarriageStatus,
  } = props;

  const FIELDS = [
    {
      name: "name_title",
      value: title,
      placeHolder: "",
      type: "dropdown",
      options: [
        { value: "mr", label: t("knowMore.mr") },
        { value: "miss", label: t("knowMore.miss") },
        { value: "lady", label: t("knowMore.lady") },
        { value: "mrs", label: t("knowMore.mrs") },
      ],
    },
    {
      name: "eng_surname",
      value: engSurname,
      placeHolder: "hkid_same",
      type: "text",
    },
    {
      name: "eng_name",
      value: engName,
      placeHolder: "hkid_same",
      type: "text",
    },
    {
      name: "hkid",
      value: hkid,
      placeHolder: "hkid_sample",
      type: "text",
    },
    {
      name: "gender",
      value: gender,
      placeHolder: "",
      type: "dropdown",
      options: [
        { value: "male", label: t("knowMore.male") },
        { value: "female", label: t("knowMore.female") },
      ],
    },
    {
      name: "dob",
      value: dob,
      placeHolder: "dob_sample",
      type: "text",
    },
    {
      name: "email",
      value: email,
      placeHolder: "email_sample",
      type: "text",
    },
    {
      name: "mobile",
      value: mobile,
      placeHolder: "mobile_sample",
      type: "text",
    },
    {
      name: "isHk",
      value: isHk,
      placeHolder: "",
      type: "dropdown",
      options: [
        { value: "yes", label: t("knowMore.yes") },
        { value: "no", label: t("knowMore.no") },
      ],
    },
    {
      name: "country",
      value: country,
      placeHolder: "",
      type: "text",
    },
    {
      name: "education",
      value: education,
      placeHolder: "",
      type: "dropdown",
      options: [
        { value: "under_secondary", label: t("knowMore.under_secondary") },
        { value: "training", label: t("knowMore.training") },
        { value: "associate", label: t("knowMore.associate") },
        { value: "bachelor", label: t("knowMore.bachelor") },
        { value: "master_above", label: t("knowMore.master_above") },
      ],
    },
    {
      name: "livingStatus",
      value: livingStatus,
      placeHolder: "",
      type: "dropdown",
      options: [
        { value: "self_house", label: t("knowMore.self_house") },
        { value: "mortgage", label: t("knowMore.mortgage") },
        { value: "dormitory", label: t("knowMore.dormitory") },
        { value: "renting", label: t("knowMore.renting") },
        {
          value: "living_with_cousin",
          label: t("knowMore.living_with_cousin"),
        },
      ],
    },
    {
      name: "phoneNum",
      value: phoneNum,
      placeHolder: "",
      type: "text",
    },
    {
      name: "marriageStatus",
      value: marriageStatus,
      placeHolder: "",
      type: "dropdown",
      options: [
        { value: "single", label: t("knowMore.single") },
        { value: "married", label: t("knowMore.married") },
        { value: "divorced", label: t("knowMore.divorced") },
        { value: "widowed", label: t("knowMore.widowed") },
      ],
    },
  ];

  const handleChange = (e: any, type?: string, fieldName?: string) => {
    if (type === "dropdown") {
      if (fieldName === "name_title") return setTitle(e);
      if (fieldName === "gender") return setGender(e);
      if (fieldName === "isHk") return setIsHk(e);
      if (fieldName === "education") return setEducation(e);
      if (fieldName === "livingStatus") return setLivingStatus(e);
      if (fieldName === "marriageStatus") return setMarriageStatus(e);
      return;
    }

    e.persist();
    const { name, value } = e.target;
    if (name === "eng_surname") return setEngSurname(value);
    if (name === "eng_name") return setEngName(value);
    if (name === "hkid") return setHkid(value);
    if (name === "dob") return setDob(value);
    if (name === "email") return setEmail(value);
    if (name === "mobile") return setMobile(value);
    if (name === "country") return setCountry(value);
    if (name === "phoneNum") return setPhoneNum(value);
  };

  return (
    <div>
      {/* Blk Header */}
      <div className="py-4 bg-primary">
        <h5 className="px-6">{t("knowMore.title")}</h5>
      </div>

      {/* Blk */}
      <div className="bg-white drop-shadow-md pb-5 px-6 pt-5 relative">
        <div className="flex flex-row justify-between flex-wrap">
          {FIELDS.map((item: any, index: number) => {
            return (
              <div className="w-[47%] pb-5" key={index}>
                <p>{t(`knowMore.${item.name}`)}</p>
                {item.type === "text" && (
                  <input
                    value={item.value}
                    name={item.name}
                    onChange={(e) => handleChange(e)}
                    className={INPUT_CLASS}
                    placeholder={
                      item.placeHolder && t(`knowMore.${item.placeHolder}`)
                    }
                  />
                )}

                {item.type === "dropdown" && (
                  <Select
                    defaultValue={item.value}
                    className={INPUT_CLASS}
                    style={{
                      marginTop: 5,
                      fontSize: 11,
                    }}
                    dropdownStyle={{
                      fontSize: 11,
                    }}
                    allowClear
                    options={item.options}
                    onChange={(e: any) =>
                      handleChange(e, "dropdown", item.name)
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
