import React from "react";

export const Menu = (props: any) => {
  const { title } = props;

  return (
    <div className="fixed h-[120px] top-0 w-screen bg-alt-bg flex flex-col z-50 px-6 pt-5 pb-1.5">
      <div className="w-36">
        <img
          src="/assets/logo_byon_white.png"
          className="w-full h-full object-cover"
          alt="byon-img"
        />
      </div>
      <div className={`flex flex-row items-end flex-1`}>
        {title && (
          <h5 className={`text-primary text-base font-semibold`}>{title}</h5>
        )}
      </div>
    </div>
  );
};
