import React, { useEffect, useState } from "react";
import { Container, openNotificationWithIcon } from "../../components";
import { useTranslation } from "react-i18next";
import { PLoan } from "./ploan";
import { KnowMoreBlk } from "./knowMore";
import { AddressBlk } from "./address";
import { BankInfoBlk } from "./bankInfo";
import { TermsBlk } from "./terms";
import { checkEmpty, validateEmail } from "src/utils";
import { history, useApplicationStore } from "src/stores";

export const Home = (props: any) => {
  const { t } = useTranslation();
  const { submitForm, checkForm } = useApplicationStore();
  const userId = new URLSearchParams(props.location.search).get("user_id");
  const service = new URLSearchParams(props.location.search).get("service");
  // applied data
  const [loading, setLoading] = useState<boolean>(true);
  // ploan data
  const [requiredAmount, setRequiredAmount] = useState<number>(100000);
  const [desiredMonth, setDesiredMonth] = useState<number>(6);
  // know more data
  const [title, setTitle] = useState<string>("");
  const [engName, setEngName] = useState<string>("");
  const [engSurname, setEngSurname] = useState<string>("");
  const [hkid, setHkid] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [dob, setDob] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [isHk, setIsHk] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [education, setEducation] = useState<string>("");
  const [livingStatus, setLivingStatus] = useState<string>("");
  const [phoneNum, setPhoneNum] = useState<string>("");
  const [marriageStatus, setMarriageStatus] = useState<string>("");
  // address data
  const [room, setRoom] = useState<string>("");
  const [floor, setFloor] = useState<string>("");
  const [block, setBlock] = useState<string>("");
  const [apartmentName, setApartmentName] = useState<string>("");
  const [road, setRoad] = useState<string>("");
  const [district, setDistrict] = useState<string>("");
  // bank info data
  const [bankName, setBankName] = useState<string>("");
  const [bankAcc, setBankAcc] = useState<string>("");
  // terms data
  const [debt, setDebt] = useState<string>("");
  const [purpose, setPurpose] = useState<string>("使用 AT 服務");
  const [agreeTerm, setAgreeTerm] = useState<boolean>(false);
  const [disclaimer, setDisclaimer] = useState<boolean>(false);
  const [privacy, setPrivacy] = useState<boolean>(false);

  useEffect(() => {
    if (!userId) return;
    checkForm(userId).then((item) => {
      if (!item) return setLoading(false);
      setTitle(item["title"]);
      setEngName(item["eng_name"]);
      setEngSurname(item["eng_surname"]);
      setHkid(item["hkid"]);
      setGender(item["gender"]);
      setDob(item["dob"]);
      setEmail(item["email"]);
      setMobile(item["mobile"]);
      setIsHk(item["is_hk"]);
      setCountry(item["country"]);
      setEducation(item["education"]);
      setLivingStatus(item["living_status"]);
      setPhoneNum(item["phone_num"]);
      setMarriageStatus(item["marriage_status"]);
      setRoom(item["room"]);
      setFloor(item["floor"]);
      setBlock(item["block"]);
      setApartmentName(item["apartment_name"]);
      setRoad(item["road"]);
      setDistrict(item["district"]);
      setBankName(item["bank_name"]);
      setBankAcc(item["bank_account"]);
      setLoading(false);
    });
  }, [checkForm, userId, setLoading]);

  const submit = async () => {
    if (!userId) {
      return openNotificationWithIcon(
        "warning",
        t("messages.warning"),
        t("messages.requiredUserId")
      );
    }

    if (!agreeTerm) {
      return openNotificationWithIcon(
        "warning",
        t("messages.warning"),
        t("messages.requiredAgreeTerm")
      );
    }

    if (!disclaimer) {
      return openNotificationWithIcon(
        "warning",
        t("messages.warning"),
        t("messages.requiredDisclaimer")
      );
    }

    if (!privacy) {
      return openNotificationWithIcon(
        "warning",
        t("messages.warning"),
        t("messages.requiredPrivacy")
      );
    }

    const isAllRequiredConfirmed = checkEmpty([
      title,
      engName,
      engSurname,
      hkid,
      gender,
      dob,
      email,
      mobile,
      isHk,
      country,
      education,
      livingStatus,
      marriageStatus,
      floor,
      apartmentName,
      road,
      district,
      bankName,
      bankAcc,
    ]);
    if (!isAllRequiredConfirmed) {
      return openNotificationWithIcon(
        "warning",
        t("messages.warning"),
        t("messages.requiredFields")
      );
    }

    // Check email
    if (!validateEmail(email)) {
      return openNotificationWithIcon(
        "warning",
        t("messages.warning"),
        t("messages.emailInvalid")
      );
    }

    // Submit form to database
    const body = {
      user_id: userId,
      required_amount: requiredAmount,
      desired_month: desiredMonth,
      title,
      eng_surname: engSurname,
      eng_name: engName,
      hkid: hkid,
      gender,
      dob,
      email,
      mobile,
      is_hk: isHk,
      country,
      education,
      living_status: livingStatus,
      phone_num: phoneNum,
      marriage_status: marriageStatus,
      room,
      floor,
      apartment_name: apartmentName,
      road,
      district,
      bank_name: bankName,
      bank_account: bankAcc,
      status: "submitted",
      service,
      debt,
      purpose,
    };

    try {
      await submitForm(body);
      openNotificationWithIcon(
        "success",
        t("messages.success"),
        t("messages.formSubmitted")
      );
      return history.push("/form-submit");
    } catch (e) {
      openNotificationWithIcon(
        "error",
        t("messages.error"),
        t("messages.formError")
      );
    }
  };

  return (
    <Container title={t("title")}>
      {!loading && (
        <div>
          {/* pLoan Blk */}
          <PLoan
            requiredAmount={requiredAmount}
            setRequiredAmount={setRequiredAmount}
            desiredMonth={desiredMonth}
            setDesiredMonth={setDesiredMonth}
          />

          <div className="pt-5" />
          {/* Know more Blk */}
          <KnowMoreBlk
            title={title}
            setTitle={setTitle}
            engName={engName}
            setEngName={setEngName}
            engSurname={engSurname}
            setEngSurname={setEngSurname}
            hkid={hkid}
            setHkid={setHkid}
            gender={gender}
            setGender={setGender}
            dob={dob}
            setDob={setDob}
            email={email}
            setEmail={setEmail}
            mobile={mobile}
            setMobile={setMobile}
            isHk={isHk}
            setIsHk={setIsHk}
            country={country}
            setCountry={setCountry}
            education={education}
            setEducation={setEducation}
            livingStatus={livingStatus}
            setLivingStatus={setLivingStatus}
            phoneNum={phoneNum}
            setPhoneNum={setPhoneNum}
            marriageStatus={marriageStatus}
            setMarriageStatus={setMarriageStatus}
          />

          <div className="pt-5" />
          {/* Address Blk */}
          <AddressBlk
            room={room}
            setRoom={setRoom}
            floor={floor}
            setFloor={setFloor}
            block={block}
            setBlock={setBlock}
            apartmentName={apartmentName}
            setApartmentName={setApartmentName}
            road={road}
            setRoad={setRoad}
            district={district}
            setDistrict={setDistrict}
          />

          <div className="pt-5" />
          {/* Bank Info Blk */}
          <BankInfoBlk
            bankName={bankName}
            setBankName={setBankName}
            bankAcc={bankAcc}
            setBankAcc={setBankAcc}
          />

          <div className="pt-5" />
          {/* T&C Blk */}
          <TermsBlk
            agreeTerm={agreeTerm}
            setAgreeTerm={setAgreeTerm}
            disclaimer={disclaimer}
            setDisclaimer={setDisclaimer}
            privacy={privacy}
            setPrivacy={setPrivacy}
            purpose={purpose}
            setPurpose={setPurpose}
            debt={debt}
            setDebt={setDebt}
          />

          <div className="pt-10" />
          {/* Submit Btn */}
          <div className="w-full mx-auto text-center">
            <button className="primary py-3 px-14" onClick={() => submit()}>
              {t("submit")}
            </button>
          </div>
        </div>
      )}
    </Container>
  );
};
