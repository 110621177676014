import React from "react";
import { Menu } from "../menu";

export const Container = (props: any) => {
  const {
    children,
    hasHeader = true,
    title,
    toPage = null,
    avatar = null,
  } = props;
  return (
    <div>
      {hasHeader && <Menu avatar={avatar} title={title} toPage={toPage} />}
      <div className="pt-[120px] pb-24 z-20">{children}</div>
    </div>
  );
};
