import { action } from "mobx";
import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import {
  errorHandling,
  isResultSuccess,
  refreshNewToken,
  setHeader,
} from "./api";

export class ApplicationStore {
  @action
  async checkForm(id: any) {
    const url = `${Configs.apiUrl}/application/check-byon?user_id=${id}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  @action
  async submitForm(body: Object) {
    const url = `${Configs.apiUrl}/application/byon-submit`;
    try {
      const result: any = await axios.post(url, body, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_APPLICATION = "applicationStore";
