import React from "react";
import { useTranslation } from "react-i18next";

const INPUT_CLASS =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

export const BankInfoBlk = (props: any) => {
  const { t } = useTranslation();
  const { bankName, setBankName, bankAcc, setBankAcc } = props;

  const FIELDS = [
    {
      name: "bank_name",
      value: bankName,
      placeHolder: "",
      type: "text",
    },
    {
      name: "bank_account",
      value: bankAcc,
      placeHolder: "",
      type: "text",
    },
  ];

  const handleChange = (e: any) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "bank_name") return setBankName(value);
    if (name === "bank_account") return setBankAcc(value);
  };

  return (
    <div>
      {/* Blk Header */}
      <div className="py-4 bg-primary">
        <h5 className="px-6">{t("bank.title")}</h5>
      </div>

      {/* Blk */}
      <div className="bg-white drop-shadow-md pb-5 px-6 pt-5 relative">
        <div className="flex flex-row justify-between flex-wrap">
          {FIELDS.map((item: any, index: number) => {
            return (
              <div className="w-[47%] pb-5" key={index}>
                <p>{t(`bank.${item.name}`)}</p>
                {item.type === "text" && (
                  <input
                    value={item.value}
                    name={item.name}
                    onChange={(e) => handleChange(e)}
                    className={INPUT_CLASS}
                    placeholder={
                      item.placeHolder && t(`bank.${item.placeHolder}`)
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
