import React from "react";
import { useTranslation } from "react-i18next";

const INPUT_CLASS =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

export const TermsBlk = (props: any) => {
  const { t } = useTranslation();
  const {
    agreeTerm,
    setAgreeTerm,
    disclaimer,
    setDisclaimer,
    privacy,
    setPrivacy,
    purpose,
    debt,
    setDebt,
  } = props;

  const FIELDS = [
    {
      name: "debt",
      value: debt,
      placeHolder: "debt",
      type: "text",
      isDisabled: false,
    },
    {
      name: "purpose",
      value: purpose,
      placeHolder: "purpose",
      type: "text",
      isDisabled: true,
    },
  ];

  const handleChange = (e: any) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "debt") return setDebt(value);
  };

  return (
    <div>
      {/* Blk Header */}
      <div className="py-4 bg-primary">
        <h5 className="px-6">{t("others.title")}</h5>
      </div>

      {/* Blk */}
      <div className="bg-white drop-shadow-md pb-5 px-6 pt-5 relative">
        {/* Purpose */}
        <div className="flex flex-row justify-between flex-wrap">
          {FIELDS.map((item: any, index: number) => {
            return (
              <div className="w-[47%] pb-5" key={index}>
                <p>{t(`others.${item.name}`)}</p>
                {item.type === "text" && (
                  <input
                    value={item.value}
                    name={item.name}
                    onChange={(e) => handleChange(e)}
                    className={INPUT_CLASS}
                    placeholder={
                      item.placeHolder && t(`others.${item.placeHolder}`)
                    }
                    disabled={item.isDisabled}
                  />
                )}
              </div>
            );
          })}
        </div>
        {/* Terms */}
        <div
          className="flex flex-row items-center"
          onClick={() => setAgreeTerm(!agreeTerm)}
        >
          {/* Check btn */}
          <div
            className={`w-[10px] h-[10px] border border-black min-w-[10px]`}
            style={{
              background: agreeTerm ? "#ffcd38" : "white",
            }}
          />

          <p className="ml-2">
            {t("others.read")}
            <span
              className="text-primary cursor-pointer"
              onClick={() =>
                window.open("https://byon.com.hk/tc/terms_conditions.html")
              }
            >
              {t("others.terms")}
            </span>
            {t("others.and")}
            <span
              className="text-primary cursor-pointer"
              onClick={() =>
                window.open(
                  "https://byon.com.hk/assets/images/online-personal-loan-ofshk-form-of-loan-agreement-not-involving-third-party-04-apr2022-final-version-1.pdf"
                )
              }
            >
              {t("others.conditions")}
            </span>
          </p>
        </div>

        {/* Terms */}
        <div
          className="flex flex-row items-start mt-2"
          onClick={() => setDisclaimer(!disclaimer)}
        >
          {/* Check btn */}
          <div
            className={`w-[10px] h-[10px] border border-black min-w-[10px] relative top-[2px]`}
            style={{
              background: disclaimer ? "#ffcd38" : "white",
            }}
          />

          <p className="ml-2">{t("others.disclaimer")}</p>
        </div>

        {/* Notes */}
        <p className="mt-5">{t("others.notes")}</p>

        {/* Yes No Btns */}
        <div className="flex flex-row items-center mt-3">
          <div
            className="flex flex-row items-center"
            onClick={() => setPrivacy(true)}
          >
            {/* Check btn */}
            <div
              className={`w-[10px] h-[10px] border border-black min-w-[10px]`}
              style={{
                background: privacy ? "#ffcd38" : "white",
              }}
            />
            <p className="ml-2">{t("others.yes")}</p>
          </div>

          <div
            className="flex flex-row items-center ml-5"
            onClick={() => setPrivacy(false)}
          >
            {/* Check btn */}
            <div
              className={`w-[10px] h-[10px] border border-black min-w-[10px]`}
              style={{
                background: !privacy ? "#ffcd38" : "white",
              }}
            />
            <p className="ml-2">{t("others.no")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
