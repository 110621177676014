import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatDollar } from "src/utils";
const { Slider } = require("antd");

const SLIDE_BALL_SIZE = 15;
const SLIDE_BALL_COLOR = "#FFCD38";
const SLIDE_COLOR = "#000";
const SLIDE_HEIGHT = 2;

export const PLoan = (props: any) => {
  const { t } = useTranslation();
  const { requiredAmount, setRequiredAmount, desiredMonth, setDesiredMonth } =
    props;

  const calculatedPrice = useMemo(() => {
    if (!requiredAmount || !desiredMonth) return t("plaon.calculating");

    return requiredAmount / desiredMonth;
  }, [requiredAmount, desiredMonth, t]);

  return (
    <div>
      {/* Blk Header */}
      <div className="py-4 bg-primary">
        <h5 className="px-6">{t("ploan.title")}</h5>
      </div>

      {/* Blk */}
      <div className="bg-white drop-shadow-md pb-5 px-6 pt-5 relative">
        {/* Required Amount */}
        <div>
          <div className="flex flex-row">
            <p className="flex-1">{t("ploan.requiredAmount")}</p>

            <p className="font-black bottom-1.5 relative">
              <span className="text-secondary text-2xl tracking-tighter mr-1">
                {formatDollar(requiredAmount)}
              </span>{" "}
              HKD
            </p>
          </div>

          <Slider
            defaultValue={requiredAmount}
            min={1000}
            max={100000} // without GC KYC is 3000
            step={1000}
            trackStyle={{
              backgroundColor: SLIDE_COLOR,
              height: SLIDE_HEIGHT,
            }}
            handleStyle={{
              backgroundColor: SLIDE_BALL_COLOR,
              borderColor: SLIDE_BALL_COLOR,
              width: SLIDE_BALL_SIZE,
              height: SLIDE_BALL_SIZE,
            }}
            onChange={(val: any) => setRequiredAmount(val)}
          />
        </div>

        {/* Month */}
        <div className="pt-2">
          <div className="flex flex-row">
            <p className="flex-1">{t("ploan.desiredMonth")}</p>

            <p className="font-black bottom-1.5 relative">
              <span className="text-secondary text-2xl tracking-tighter mr-1">
                {desiredMonth}
              </span>{" "}
              {t("ploan.month")}
            </p>
          </div>

          <Slider
            defaultValue={desiredMonth}
            min={3}
            max={6}
            step={3}
            trackStyle={{
              backgroundColor: SLIDE_COLOR,
              height: SLIDE_HEIGHT,
            }}
            handleStyle={{
              backgroundColor: SLIDE_BALL_COLOR,
              borderColor: SLIDE_BALL_COLOR,
              width: SLIDE_BALL_SIZE,
              height: SLIDE_BALL_SIZE,
            }}
            onChange={(val: any) => setDesiredMonth(val)}
          />
        </div>

        {/* Calculator */}
        <div className="pt-5">
          <p>{t("ploan.terms")}</p>
          <p className="font-black pt-1">
            <span className="text-primary text-2xl tracking-tighter mr-1">
              {formatDollar(calculatedPrice)}
            </span>{" "}
            HKD
          </p>
          <p className="text-[9px] pt-2">{t("ploan.notes")}</p>
        </div>
      </div>
    </div>
  );
};
